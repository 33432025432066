<template>
  <div>
    <!-- 列表页 -->
    <div class="view-wrap">
      <div class="search">
        <el-form :inline="true">
          <el-form-item class="hiddenFormItem">
            <el-input
              style="width: 100%"
              v-model="searcForm.param"
              placeholder="输入商品名称/编号/条码"
            ></el-input>
          </el-form-item>
          <el-form-item label="类别：">
            <el-cascader
              v-model="typeId"
              style="width: 100%"
              :props="typeProps"
              :options="typeList"
              placeholder="全部"
              @change="handleChange"
              :show-all-levels="false"
              clearable
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.name }}</span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item label="仓库：">
            <el-select
              v-model="CheckWms"
              style="width:360px"
              multiple
              placeholder="全部"
              @change="changeSelect"
            >
              <el-option
                v-for="item in WmsData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData"
              ><i class="el-icon-search" /> 查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-loading="loading" class="view">
        <SimpleTable
          :data="tableData"
          :tableFields="tableItem"
          :operation="operationEvent"
          :currentPage="searcForm.pageNum"
          :pageSize="searcForm.pageSize"
          :total="total"
          @sizeChange="changePageSize"
          @currentChange="changeCurrentPage"
        >
          <div slot="operation" style="text-align: center" slot-scope="row">
            <el-tooltip
              class="item"
              effect="dark"
              content="期初设置"
              placement="top-start"
            >
              <el-button type="text" :disabled="row.data.isInitial === 1" @click="setting(row.data)"
                >期初设置</el-button>
            </el-tooltip>
          </div>
        </SimpleTable>
      </div>
    </div>
    <!-- 设置期初页 -->
    <miaDialog width="55%" ref="SettingExpect" v-if="settingVisible">
      <div slot="content">
        <SetExpect :data="currenData" :WmsData="WmsData" @refresh="refreshData" @close="settingVisible = false" />
      </div>
    </miaDialog>
  </div>
</template>
<script>
import SetExpect from './SetExpect.vue'
import { GetExpectList } from "@/api/expectInstall.js";
import { GetWmsList } from "@/api/warehouse";
import { GetProductTypeList } from "@/api/productType";
export default {
  components:{SetExpect},
    data() {
    return {
      settingVisible: false,
      loading: false,
      // 仓库数据
      WmsData: [],
      // 选择的仓库
      CheckWms: [],
      // 类别数据
      typeList: [],
      typeId: [null],
      typeProps: {
        value: "id",
        label: "name",
        children: "list",
      },
      searcForm: {
        param: "",
        pageNum: 1,
        pageSize: 10,
        typeId: "",
        wmsIds: "",
      },
      total: 0,
      tableData: [],
      tableItem: [
        { label: "操作", type: "operation" },
        { prop: "code", label: "编号", type: "default" },
        { prop: "name", label: "商品名称", type: "default" },
        { prop: "spec", label: "规格", type: "default" },
        { prop: "barCode", label: "条码", type: "default" },
        { prop: "typeName", label: "类别", type: "default" },
        { prop: "unit", label: "基础单位", type: "default" },
        { prop: "initialNumber", label: "期初数量", type: "default" },
        { prop: "initialAverageCost", label: "期初平均成本", type: "price" },
        { prop: "initialCost", label: "期初总成本", type: "price" },
        
      ],
      operationEvent: [],
      currenData: '',
      currenType: null,
    };
  },
  watch:{
    CheckWms(val) {
      if(val.includes('全部')) {
        this.searcForm.wmsIds = ''
      } else {
        this.searcForm.wmsIds = JSON.parse(JSON.stringify(val)).join(',')
      }
    }
  },
  activated() {
    this.getData();
    this.getWms();
    this.getType();
  },
  methods:{
    handleChange(value) {
      if (value.length !== 0) {
        this.searcForm.typeId = value[value.length - 1];
      } else {
        this.searcForm.typeId = "";
      }
    },
    getWms() {
      GetWmsList({}).then((res) => {
        if (res.code === 1) {
          this.WmsData = res.data.records;
        } else {
          this.WmsData = [];
        }
      });
    },
    getType() {
      GetProductTypeList().then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          this.typeList = records;
          this.getTreeData(this.typeList);
        } else {
          this.typeList = [];
        }
      });
    },
    
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].list.length < 1) {
          // list若为空数组，则将删除
          delete data[i].list;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].list);
        }
      }
      return data;
    },
    getData() {
      this.loading = true
      GetExpectList(this.searcForm).then((res) => {
        if (res.code === 1) {
          const { records, total,  current} = res.data;
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              this.loading = false;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
          this.loading = false
        } else {
          this.tableData = [];
          this.loading = false
        }
      });
    },
    setting(data){
      this.currenData = JSON.parse(JSON.stringify(data))
      this.settingVisible = true;
      this.$nextTick(() => {
        this.$refs.SettingExpect.init("期初库存设置");
      });
    },
    refreshData(){
      this.settingVisible = false
      this.getData();
    },
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 全选
    selectAll(){
      if (this.CheckWms.length < this.WmsData.length) {
        this.CheckWms = []
        this.CheckWms.map((item) => {
          this.CheckWms.push(item.name)
        })
        this.CheckWms.unshift('全部')
      } else {
        this.CheckWms = []
      }
    },
    // 选择
    changeSelect(val){
      if (!val.includes('全部') && val.length === this.WmsData.length) {
        this.CheckWms.unshift('全部')
      } else if (val.includes('全部') && (val.length - 1) < this.WmsData.length) {
        this.CheckWms = this.CheckWms.filter((item) => {
          return item !== '全部'
        })
      }
    }
  }
}
</script>