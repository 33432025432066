<template>
  <div>
    <el-row>
      <span>商品名称：{{ data.name }}</span>
    </el-row>
    <el-row v-if="currenType == 1 || currenType == 0" style="margin-top: 20px">
      <el-col :span="2">库存设置： </el-col>
      <el-col :span="22">
        <Editable
          ref="Editable"
          :type="currenType"
          :miaTable="Table"
          :tableColumn="tableColumnList"
          :expire="data.expire"
          :WmsData="WmsData"
        />
      </el-col>
    </el-row>
    <el-row v-if="currenType == 2" style="margin-top: 20px">
      <el-col :span="2">库存设置： </el-col>
      <el-col :span="15" style="display: flex">
        <Editable
          ref="Editable"
          :type="currenType"
          :miaTable="Table"
          :tableColumn="tableColumnList"
          :expire="data.expire"
          :WmsData="WmsData"
        />
      </el-col>
    </el-row>
    <div style="width: 100%; text-align: center; margin-top: 50px">
      <el-button type="primary" @click="disposeData">提交</el-button>
      <el-button @click="$emit('close')">取消</el-button>
    </div>
  </div>
</template>
<script>
import { SaveInstall,GetDetail } from "@/api/expectInstall.js";
export default {
  data() {
    return {
      currenType: null,
      tableColumnList: [],
      Table:[]
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    WmsData: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.getInfo()
    this.init();
  },
  methods: {
    getInfo(){
      GetDetail({productId: this.data.id}).then(res => {
        if(res.code) {
          if(res.data.length !== 0){
            // 拿之前的数据
           this.Table = res.data
          }
        }
      })
    },
    init() {
      if (!this.data.isBatch && !this.data.isSerial) {
        // 都未开启  为普通商品
        this.currenType = 0;
        this.tableColumnList = [
          {
            prop: "wmsId",
            label: "仓库",
            type: "select",
            data: [],
            edit: true,
            required: true,
          },
          {
            prop: "initialNumber",
            label: "期初数量",
            type: "number",
            edit: true,
            required: true,
            min: 1,
            max: 99999,
            precision: 0,
          },
          {
            prop: "initialAverageCost",
            label: "期初平均成本",
            type: "pirce",
            edit: true,
            required: false,
            min: 0.00,
            max: 99999.99,
            precision: 2,
          },
          {
            prop: "initialCost",
            label: "期初总成本",
            type: "pirce",
            edit: false,
            required: false,
          },
        ];
      } else if (this.data.isBatch) {
        // 1 为批次商品
        this.currenType = 1;
        this.tableColumnList = [
          {
            prop: "wmsId",
            label: "仓库",
            type: "select",
            data: [],
            edit: true,
            required: true,
          },
          // {
          //   prop: "batchNo",
          //   label: "批次号",
          //   type: "input",
          //   edit: true,
          //   required: true,
          // },
          // {
          //   prop: "productionDate",
          //   label: "生产日期",
          //   type: "date",
          //   edit: true,
          //   required: true,
          // },
          // {
          //   prop: "expire",
          //   label: "保质期天数",
          //   type: "input",
          //   edit: false,
          //   required: false,
          // },
          // {
          //   prop: "dateDue",
          //   label: "到期日期",
          //   type: "input",
          //   edit: false,
          //   required: false,
          // },
          {
            prop: "initialNumber",
            label: "期初数量",
            type: "number",
            edit: true,
            required: true,
            min: 1,
            max: 99999,
            precision:0,
          },
          {
            prop: "initialAverageCost",
            label: "期初平均成本",
            type: "pirce",
            edit: true,
            required: false,
            min: 0.00,
            max: 99999.99,
            precision: 2,
          },
          {
            prop: "initialCost",
            label: "期初总成本",
            type: "pirce",
            edit: false,
            required: false,
          },
        ];
      } else if (this.data.isSerial) {
        // 2 为序列码商品
        this.currenType = 2;
        this.tableColumnList = [
          {
            prop: "wmsId",
            label: "仓库",
            type: "select",
            data: [],
            edit: true,
            required: true,
          },
          {
            prop: "initialNumber",
            label: "期初数量",
            type: "number",
            edit: false,
            required: true,
            min: 1,
            max: 99999,
            precision: 0,
          },
          {
            prop: "initialAverageCost",
            label: "期初平均成本",
            type: "pirce",
            edit: true,
            required: false,
            min: 0.0,
            max: 99999.99,
            precision: 2,
          },
          {
            prop: "initialCost",
            label: "期初总成本",
            type: "pirce",
            edit: false,
            required: false,
          },
        ];
      }
      const data  = this.WmsData.map(val => {
        val.disabled = false 
        return val
      });
      this.tableColumnList[0].data = data
    },
    // 处理数据
    disposeData() {
      let data = JSON.parse(
        JSON.stringify(this.$refs["Editable"].$data.tableData)
      );
      // 1. 先过滤数组里面 数组对象所有键为空的数据
      if (this.currenType === 0) {
        // 普通商品
        const list = data.filter((item) => {
          {
            return item.wmsId || item.initialNumber
          }
        });
        if (list.length === 0) {
          this.$message.error("无有效数据可提交");
        } else {
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            delete element.index;
            for (let j = 0; j < Object.keys(element).length; j++) {
              const item = Object.keys(element)[j];
              if (item === "wmsId" || item === "initialNumber") {
                const key = element[item];
                if (key === null || key === "") {
                  this.$message.error("表单" + (i + 1) + "项的必填项不能为空");
                  return false;
                }
              }
              const key = element[item];
              if (key === null) {
                delete element[item];
              }
            }
          }
          if (list.length !== 0) {
            const form = {
              id: this.data.id,
              inventoryList: list,
            };
            this.SaveTable(form);
          } else {
            this.$message.error("无有效数据可提交");
          }
        }
      }
      // 批次商品
      if (this.currenType === 1) {
        const list = data.filter((item) => {
          {
            return (
              item.wmsId  ||
              item.initialNumber ||
              item.batchNo ||
              item.productionDate
            );
          }
        });

        if (list.length === 0) {
          this.$message.error("无有效数据可提交");
        } else {
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            delete element.index;
            delete element.expire;
            delete element.serialNoList;
            for (let j = 0; j < Object.keys(element).length; j++) {
              const item = Object.keys(element)[j];
              if (
                item === "wmsId" ||
                item === "initialNumber" ||
                item === "batchNo" ||
                item === "productionDate"
              ) {
                const key = element[item];
                if (key === null || key === "") {
                  this.$message.error("表单" + (i + 1) + "项的必填项不能为空");
                  return false;
                }
              }
              const key = element[item];
              if (key === null) {
                delete element[item];
              }
            }
          }
          if (list.length !== 0) {
            const form = {
              id: this.data.id,
              inventoryList: list,
            };
            this.SaveTable(form);
          } else {
            this.$message.error("无有效数据可提交");
          }
        }
      }
      // 序列码商品
      if (this.currenType === 2) {
        // 过滤数组数据中数量不为0的
        const list = data.filter((val) => {
          return val.initialNumber || val.wmsId;
        });
        if (list.length === 0) {
          this.$message.error("无有效数据可提交，请检查每项商品的序列码列表");
        } else {
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            if (!element.wmsId) {
              this.$message.error("表单" + (i + 1) + "项的必填项不能为空");
              return false;
            }
            if(!element.initialNumber) {
              this.$message.error("请填写第" + (i + 1) + "列表下的序列码列表");
              return false;
            }
          }
          this.filteData(list)
        }
      }
    },
    filteData(list) {
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        const childList = []
        for (let j = 0; j < element.serialNoList.length; j++) {
          const item = element.serialNoList[j];
          if (item.remark) {
            this.$message.error("请修改第" + (i + 1) + "列表下的重复序列码");
            return false;
          }
          if(item.code) {
            childList.push(item)
          }
        }
        element.serialNoList = childList.map(val => val.code)
      }
      if (list.length !== 0) {
        const form = {
          id: this.data.id,
          inventoryList: list,
        };
        this.SaveTable(form);
      } else {
        this.$message.error("无有效数据可提交");
      }
    },
    SaveTable(data) {
      SaveInstall(data).then((res) => {
        if (res.code === 1) {
          this.$emit("refresh");
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>